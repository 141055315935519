import defaultCategories from '@/settings/categories/default';

export function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export const getPageUrl = (handle: any) => {
  if (handle.includes('reebelos-gift-card')) return `/${handle}`;

  if (
    ['baby', 'power-tools', 'sports-and-fitness'].includes(handle) ||
    Object.keys(defaultCategories).includes(handle)
  ) {
    switch (handle) {
      case 'baby':
        return `/category/baby`;
      case 'power-tools':
        return `/category/tools-home-improvement`;
      case 'sports-and-fitness':
        return `/category/sports-fitness`;
      default:
        return `/category/${handle}`;
    }
  }

  return `/collections/${handle}`;
};
