import { getEdgeUrl } from '@/lib/getEdgeUrl';
import { ContentT } from './index';

const LaptopsDesktop = getEdgeUrl('images/homepage/banner/laptops-desktop.png');
const LaptopsMobile = getEdgeUrl('images/homepage/banner/laptops-mobile.png');
const IpadsDesktop = getEdgeUrl('images/homepage/banner/ipads-desktop.png');
const IpadsMobile = getEdgeUrl('images/homepage/banner/ipads-mobile.png');

const CONFIG: ContentT[] = [
  {
    title: 'Refurbished Laptops',
    description: {
      desktop:
        'Explore a wide variety of new expertly refurbished laptop picks - up to 70% off.',
      mobile:
        'Explore a wide variety of new & refurbished laptop picks - up to 70% off.',
    },
    cta: {
      label: 'Shop Now',
      link: 'laptops',
      tracking: 'Laptops',
    },
    banner: { desktop: LaptopsDesktop, mobile: LaptopsMobile },
    theme: 'light',
  },
  {
    title: 'Refurbished iPads',
    description: {
      desktop:
        'On the go or on the fly - new and refurbished iPad upgrades that can get it all done.',
      mobile:
        'On the go or on the fly - new and refurbished iPad that can get it all done.',
    },
    cta: {
      label: 'Shop Now',
      link: 'apple-ipads',
      tracking: 'iPads',
    },
    banner: { desktop: IpadsDesktop, mobile: IpadsMobile },
    theme: 'dark',
  },
];

export default CONFIG;
