import { getEdgeUrl } from '@/lib/getEdgeUrl';
import { ContentT } from './index';

const IpadsDekstop = getEdgeUrl('images/homepage/banner/ipads-desktop.png');
const IpadsMobile = getEdgeUrl('images/homepage/banner/ipads-mobile.png');
const MacbooksDesktop = getEdgeUrl(
  'images/homepage/banner/macbooks-desktop.png',
);
const MacbooksMobile = getEdgeUrl('images/homepage/banner/macbooks-mobile.png');

const CONFIG: ContentT[] = [
  {
    title: 'Refurbished iPads',
    description: {
      desktop:
        'On the go or on the fly - refurbished iPad upgrades that can get it done.',
      mobile:
        'On the go or on the fly - refurbished iPad upgrades that can get it done.',
    },
    cta: {
      label: 'Shop Now',
      link: 'apple-ipads',
      tracking: 'iPads',
    },
    banner: { desktop: IpadsDekstop, mobile: IpadsMobile },
    theme: 'dark',
  },
  {
    title: 'Refurbished MacBooks',
    description: {
      desktop:
        'For all the work, fun, and play- certified refurbished Apple MacBooks up to 70% off.',
      mobile:
        'For all the work, fun, and play- certified refurbished MacBooks up to 70% off.',
    },
    cta: {
      label: 'Shop Now',
      link: 'apple-macbooks',
      tracking: 'MacBooks',
    },
    banner: { desktop: MacbooksDesktop, mobile: MacbooksMobile },
    theme: 'light',
  },
];

export default CONFIG;
