import { Breakpoints } from '@/settings/breakpoints';

export const vendorsBreakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  [Breakpoints.XS]: {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  [Breakpoints.SM]: {
    slidesPerView: 2.5,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
};
