import acer from '@/public/images/homepage/vendors/acer.jpg';
import dell from '@/public/images/homepage/vendors/dell.jpg';
import sony from '@/public/images/homepage/vendors/sony.jpg';
import lenovo from '@/public/images/homepage/vendors/lenovo.jpg';

export const getVendorImage = (brand: string): StaticImageData => {
  const images: Record<string, StaticImageData> = {
    dell,
    sony,
    lenovo,
    acer,
  };

  return images[brand];
};
