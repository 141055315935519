import cn from 'classnames';
import Link from 'next/link';
import Image from 'next/future/image';
import certifiedBlack from '@/public/icons/product/certifiedBlack.svg';
import { VendorT } from '@/lib/homepage/types';

const VisitStore = ({
  vendor,
  brand,
  className,
}: {
  vendor: VendorT;
  brand: string;
  className: string;
}) => (
  <Link href={`/collections/vendor/${vendor}`} passHref>
    <a className={cn('flex items-center hover:underline', className)}>
      <div className="-mt-0.5 mr-1.5 h-4 w-4">
        <Image alt="certified" src={certifiedBlack} className="h-full w-full" />
      </div>
      <p className="text-sm font-extrabold">{`Visit ${brand} store`}</p>
      <div className="ml-3 w-3">
        <svg viewBox="0 0 100 100">
          <path
            d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
            transform="translate(100, 100) rotate(180) "
          />
        </svg>
      </div>
    </a>
  </Link>
);

export default VisitStore;
