import cn from 'classnames';
import { VendorT } from '@/lib/homepage/types';
import vendors from '@/settings/vendors';
import { VendorKey } from '@/settings/vendors/types';
import VisitStore from './VisitStore';

const Navigation = ({
  vendor,
  setVendor,
  brand,
  title,
  usVendors,
}: {
  vendor: VendorT;
  setVendor: React.Dispatch<React.SetStateAction<VendorT>>;
  brand: string;
  title: string;
  usVendors: VendorT[];
}) => {
  if (!vendor) return null;

  return (
    <div className="mt-3 flex items-center justify-between">
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="hide-scrollbar flex w-fit items-center gap-2 overflow-hidden overflow-x-scroll font-extrabold"
      >
        {usVendors.map((v) => (
          <button
            onClick={() => {
              setVendor(v);
            }}
            key={v}
            type="button"
            className={cn(
              'h-8 whitespace-nowrap rounded-full px-5 text-xs transition duration-150 ease-in-out',
              {
                '!bg-white': vendors[v as VendorKey].title === title,
                '!bg-[#E1E2E2]  hover:!bg-white':
                  vendors[v as VendorKey].title !== title,
              },
            )}
          >
            {vendors[v as VendorKey].title}
          </button>
        ))}
      </div>
      <VisitStore vendor={vendor} brand={brand} className="hidden lg:flex" />
    </div>
  );
};

export default Navigation;
