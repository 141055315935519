import useSWR from 'swr';
import settings from '../settings';

export default function useOriginCountry() {
  const response = useSWR<{ country: string }>('https://location.reebelo.com');

  return response.data?.country;
}

export function getShortenCountryName() {
  return settings.store === 'reebelo-us'
    ? settings.country_code
    : settings.country;
}
