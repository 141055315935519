/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import useSWR from 'swr';
import { ReplacedProduct } from '@/lib/collections/catalogue/helpers';
import { Breakpoints } from '@/settings/breakpoints';
import Slider from '../slider/Slider';
import { LazyImage } from '../collections/ProductBoxBig';
import settings, { priceFormater } from '@/settings';
import { getOfferUri } from '@/lib/offer';

const breakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 1.5,
    slidesPerGroup: 1,
    spaceBetween: 10,
  },
  [Breakpoints.XS]: {
    slidesPerView: 2.5,
    slidesPerGroup: 1,
    spaceBetween: 10,
  },
  [Breakpoints.MD]: {
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 10,
  },
  [Breakpoints.LG]: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 10,
  },
  [Breakpoints.XL]: {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 10,
  },
};

const Placeholder = () => (
  <>
    <div className="hide-scrollbar flex w-full items-start justify-start overflow-x-scroll text-gray-700 md:justify-between">
      <div className="hidden h-8 w-64 animate-pulse rounded bg-[#E1E2E2] md:block lg:w-80"></div>
      <div className="flex shrink-0 items-center gap-3 font-extrabold">
        {[0, 1, 2].map((i) => (
          <div
            key={i}
            className="h-8 w-32 animate-pulse rounded-full !bg-[#E1E2E2] px-5 transition duration-150 ease-in-out"
          />
        ))}
      </div>
    </div>
    <div className="hide-scrollbar relative mt-5 flex gap-3 overflow-scroll">
      {[0, 1, 2, 3, 4].map((i) => (
        <div
          key={i}
          className={cn(
            'min-w-[178px] flex-1 animate-pulse rounded-md !bg-[#E1E2E2] sm:min-w-[250px]',
            {
              'h-[259px]': settings.store !== 'reebelo-us',
              'h-[188px] sm:h-[236px]': settings.store === 'reebelo-us',
            },
          )}
        />
      ))}
    </div>
  </>
);

type CollectionT = {
  title: string;
  collectionHandle: string;
  collectionTitle: string;
  randomize: boolean;
  pskus: string[];
  offers: ReplacedProduct[];
};

type DataT = {
  collections: CollectionT[];
};

const MostPopularDevices = () => {
  const [view, setView] = useState<number>(0);
  const { data, error } = useSWR<DataT>('api/most-popular-devices', {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  const collections = data?.collections;

  // Placeholder when no data, or there's an error, which implies another refetch will be triggered.
  if (collections == null || error) return <Placeholder />;

  if (
    collections != null &&
    collections.length > 0 &&
    collections.some((collection) => collection.offers.length > 0) &&
    !error
  ) {
    return (
      <div>
        <div className="hide-scrollbar flex w-full flex-col items-start justify-start gap-4  text-gray-700 md:justify-between">
          <div className="flex w-full justify-between">
            <h2 className="flex shrink-0 items-center text-lg font-semibold md:text-xl ">
              Most Popular Devices
            </h2>
            {collections[view].collectionHandle && (
              <Link
                href={`/collections/${collections[view].collectionHandle}`}
                passHref
              >
                <a className="flex items-center gap-2 lg:hidden ">
                  <p className="text-xs font-extrabold">{`See more`}</p>
                  <div className="w-3 lg:block">
                    <svg viewBox="0 0 100 100">
                      <path
                        d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                        transform="translate(100, 100) rotate(180) "
                      />
                    </svg>
                  </div>
                </a>
              </Link>
            )}
          </div>
          <div className="flex w-full flex-row justify-between">
            <div className="hide-scrollbar flex w-fit items-center gap-2 overflow-x-scroll font-extrabold">
              {collections.map((collection: CollectionT, index) => (
                <button
                  onClick={() => {
                    if (index !== view) setView(index);
                  }}
                  key={collection.title}
                  type="button"
                  className={cn(
                    'h-8 whitespace-nowrap rounded-full px-5 text-xs transition duration-150 ease-in-out',
                    {
                      '!bg-white': view === index,
                      '!bg-[#E1E2E2]  hover:!bg-white': view !== index,
                    },
                  )}
                >
                  {collection.title}
                </button>
              ))}
            </div>
            {collections[view].collectionHandle && (
              <Link
                href={`/collections/${collections[view].collectionHandle}`}
                passHref
              >
                <a className="hidden items-center gap-2 lg:flex ">
                  <p className="text-sm font-extrabold">{`See all ${collections[view].collectionTitle}`}</p>
                  <div className="w-3 lg:block">
                    <svg viewBox="0 0 100 100">
                      <path
                        d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                        transform="translate(100, 100) rotate(180) "
                      />
                    </svg>
                  </div>
                </a>
              </Link>
            )}
          </div>
        </div>
        <div className="relative mt-3 sm:mt-4 md:mt-5">
          <Slider breakpoints={breakpoints} speed={300}>
            {collections[view].offers.map((offer) => {
              const offerTitle = offer.title.split(' - ')[0];

              return (
                <div
                  key={offer.handle}
                  className="flex h-full w-full flex-col items-center justify-center rounded-xl border bg-white"
                >
                  <Link
                    href={getOfferUri({
                      tags: offer.tags,
                      handle: offer.handle,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any)}
                    passHref
                  >
                    <a className="block w-full px-2 py-4 xs:px-3 sm:py-5">
                      <div className="relative pb-[75%]">
                        <div className="absolute left-0 top-1/2 h-3/4 w-full -translate-y-1/2">
                          <LazyImage
                            src={
                              (offer.imageSrc ??
                                offer.images?.[0] ??
                                '') as string
                            }
                            alt={offerTitle as string}
                            fill={true}
                            className="object-contain"
                          />
                        </div>
                      </div>
                      <h3 className="two-line-ellipsis text-center text-xs font-semibold text-gray-700 xxs:text-sm">
                        {offerTitle}
                      </h3>
                      <div className="text-center text-xs text-gray-700">
                        From {priceFormater.long(offer.price as number)}
                      </div>
                    </a>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }

  // No data
  return null;
};

export default MostPopularDevices;
