import { useMemo, useEffect, useState } from 'react';
import { RecentlyViewedProductT } from '@lambda/apis/src/dynamoDb/types';
import { isEmpty, uniqBy } from 'lodash';
import dayjs from 'dayjs';
import settings from '@/settings';
import { logger } from './logger';
import useCustomer from './customer/useCustomer';

export const useRecentlyViewed = (value?: RecentlyViewedProductT) => {
  const { customer } = useCustomer();

  const email = useMemo(() => customer?.email || '', [customer]);

  const [fetching, setFetching] = useState(false);

  const [retrievedRecentlyViewed, setRetrievedRecentlyViewed] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState(() => {
    try {
      if (typeof window !== 'undefined') {
        const item = window.localStorage.getItem('recentlyViewed');

        return item ? JSON.parse(item) : [];
      }

      return [];
    } catch (error) {
      logger.error(
        { error },
        'Error retrieving recently viewed from local storage:',
      );

      return [];
    }
  });

  useEffect(() => {
    const handleData = async () => {
      setFetching(true);

      try {
        // Retrieve information from the database
        const response = await fetch(
          `/api/recently-viewed?email=${email}&store=${settings.store}`,
        );

        const data = await response.json();

        setRetrievedRecentlyViewed(data.recentlyViewed);
        setFetching(false);
        // Combine the database information with the data stored locally
        const combinedData = uniqBy(
          [...data.recentlyViewed, ...recentlyViewed].sort((a, b) => {
            if (dayjs(a.addedAt).isBefore(dayjs(b.addedAt))) return 1;
            if (dayjs(a.addedAt).isAfter(dayjs(b.addedAt))) return -1;

            return 0;
          }),
          'sku',
        );

        // Limit Combined Data to 25 Products
        setRecentlyViewed(combinedData.slice(0, 25));
      } catch (error) {
        logger.error({ error }, 'Error fetching data');

        setFetching(false);
      }
    };

    if (email) handleData();
  }, [email]);

  useEffect(() => {
    const saveData = async () => {
      try {
        window.localStorage.setItem(
          'recentlyViewed',
          // Limit Stored Data to 25 Products
          JSON.stringify(recentlyViewed.slice(0, 25)),
        );

        if (
          email &&
          recentlyViewed.length > 0 &&
          JSON.stringify(retrievedRecentlyViewed) !==
            JSON.stringify(recentlyViewed)
        ) {
          await fetch('/api/recently-viewed', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              store: settings.store,
              email,
              // Limit Stored Data to 25 Products
              data: recentlyViewed.slice(0, 25),
            }),
          });
        }
      } catch (error) {
        logger.error({ error }, 'Error saving to local storage');
      }
    };

    saveData();
  }, [recentlyViewed]);

  const updateRecentlyViewed = (newValue: RecentlyViewedProductT) => {
    setRecentlyViewed((prevValue: RecentlyViewedProductT[]) => {
      let updatedValue = prevValue.filter(
        (item: RecentlyViewedProductT) => item.sku !== newValue.sku,
      );

      updatedValue = [newValue, ...updatedValue];

      return updatedValue;
    });
  };

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(recentlyViewed[0]))
      updateRecentlyViewed(value);
  }, [value, recentlyViewed]);

  // Hide the most recent offer if it matches the one already visible on the product page.
  if (!isEmpty(value)) return [recentlyViewed.slice(1, 25), fetching];

  return [recentlyViewed.slice(0, 25), fetching];
};
